.Adu{
    width: 100%;
    overflow: hidden;
}
.aduList ul{
    padding: 0% 5%;
}
.aduList ul li{
    padding: 0;
    margin: 4% 0;
}
#adubtn{
    margin: 0 auto;
}
#adusectLeft{
    gap: 29%;
}

@media screen and (min-width:1900px) {

 
    #adurighttxt{
        margin-top: 110px;
    }
    #adulefttxt{
        margin-top: 110px;
    }

}
@media screen and (min-width:1750px) {

 
    #adulefttxt{
        margin-left: -13%;
    }
    #aduleftimg{
        width: 150%;
    }
}
@media screen and (min-width:1590px) {

    #adulefttxt{
        margin-top: 80px;
    }
    #adulefttxt{
        margin-left:-18%;
    } 
/*     #adusectLeft{
        gap: 2%;
    } */
    
}
@media screen and (min-width:1300px) {
    #adusectLeft img{
        margin-left: -22%;
    }
    #adusectLeft {
        gap:21%;
    }
    
    #adulefttxt{
        margin-top: 30px;
    }

}
