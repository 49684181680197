.sectStart{
    margin-bottom: 5%;
}
#g{
    margin-top: 250px;
}

.galGrids{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height:auto;
    gap: 3rem;
}
.galGrid img{
    width: 100%;
}
.galGrid .category{
    text-align: center;
    height: auto;
}
.galGrid .category a{
    text-decoration: none;
    color: inherit;
}
.galGrid .category a:hover{
    text-decoration: none;
    color: inherit;
}
.galGrid .category h4{
    margin-top: 17.5px;
    margin-bottom: 7.5px;
}
.galGrid .category button{
    margin: 25px 37%;
    padding-left: 7%;
    padding-right: 7%;
}


/* Gallery Project */
.goBack{
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}
.back{
    text-decoration: none;
}
.projectName{
    margin-bottom: 30px;
}
.galProjects .galGrids{
    grid-template-columns: 1fr 1fr 1fr;
    
}
.galProjects .galGrids .galPics{
    height: 248px;
    cursor: pointer;
}
.galProject .galGrids .galPics{
    height: 248px;
    cursor:pointer;
}
.galProject .galGrids{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* gap:3%; */
}
.CatPics{
    height:378.15px;
}
/*gallery modal styling */
.lightbox-modal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.lightbox-modal .lightbox-modal-body {
    max-height: calc(100vh - 120px);
}
.modal-body{
    width: 100%;
    padding: 0;
}
.lightbox-modal .img-fluid {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
}
.modal-footer{
    padding:0.3%;
    background: #00000094;
    border-top: 0;
}
@media screen and (min-width:1700px) {
    #galSects{
        margin-top: 120px;
    }
}
@media screen and (max-width:820px) {
    #g{
        margin-top: 180px;
    }
    .galGrid .category button{
        display: none;
        margin: 25px 37%;
        width: max-content ;

    }
    .galProject .galGrids{
        grid-template-columns: 1fr 1fr 1fr;
        /* gap:3%; */
    }
    .galProject .galGrids .galPics{
        height: 200px;
    }
    .CatPics{
        height:300px;
    }
}
@media screen and (max-width:480px) {
    .galGrids{
        display: grid;
        grid-template-columns:1fr;
       /*  gap:0; */
    }
    .galGrid .category button{
        margin: 25px 33%;
        display: block ;
    }
    .galProjects .galGrids{
        grid-template-columns: 1fr 1fr;
    }
    .galProjects .galGrids .galGrid button{
        display: none;
    }
    .galProject .galGrids{
        grid-template-columns: 1fr 1fr;
        gap:3%;
        overflow-y: scroll;
    }
    .galProjects .galGrids .galPics{
        height: 130px;
    }
    .galProject .galGrids .galPics{
        height: 130px;
    }
    .CatPics{
        height:250px;
    }
}