/*style importation*/

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800&family=Source+Sans+Pro:wght@400;600;700&display=swap');

*{
    font-family: 'Nunito Sans', sans-serif;
}
li{
    padding: 0;
    margin: 0;
}
html{
    width: 100%;
    overflow-x: hidden;
}
body{
    width: 100%;
    
}
h1{
    font-size:3rem;
    font-weight:800;
    letter-spacing:-2%;
    line-height:56px;
}
h2{
    font-size:2.5rem;
    font-weight:800;
    letter-spacing:0%;
    line-height:48px;
}
h3{
    font-size:2rem;
    font-weight:700;
    letter-spacing:0%;
    line-height:40px;
}
h4{
    font-size:1.25rem;
    font-weight:600;
    letter-spacing:0%;
    line-height:32px;
}
.body{
    font-size:1rem;
    font-weight:600;
    letter-spacing:0%;
    line-height:24px;
}
.lead{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 26px;
}
.caption{
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 22px
}
.subheading{
    font-size: 0.875rem;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
    color: #006AAA;
}

.btnPrimary{
    display: flex;
    text-align: center;
    width: max-content;
    /* height: 48px; */
    border-radius: 4px;
    padding: 12px 3%;
    background: #006AAA;
    color: #FFFFFF;
    border: none;
}
a{
    text-decoration: none;
    color: inherit;
}
a:hover{
    text-decoration: none;
    color: inherit;
}
#outBtn{
    margin:auto auto;
}
img {
    pointer-events: none
}
.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.banner{
    height: fit-content;
    position: relative;
}
.banner img{
   /*  position: absolute;
    z-index: -1; */
    width: 100%;
}

.banner .overlay{
    width: 100%;
    height: 100%;
    background: #32323200;
    position: absolute;
    top: 0;
    left: 0;

}
.banner .overlay .sbtxt{
    position: relative;
    width: 1118px;
    left:7px;
    top:130px;
    margin:0 9.5%;
    color:#F2F2F2;
}
.sects{
    width: 82.8%;
    margin:50px 8%;
}
.doublePage{
    display: flex;
    gap: 6%;
}
.doublePage div{
    width: 55%;
}

.sectRight{
    width: 91%;
    margin:120px 9%;

    display: grid;
    grid-template-columns: 48% 30%;
    gap:7%;
    overflow-x: hidden;
    overflow-y: auto;
    
}
.sectLeft{
    width: 95%;
    margin:120px -6%;
    display: grid;
    grid-template-columns: 30% 46% ;
    gap:28%;
    overflow-x: hidden;

    overflow-y: auto;

}
.sectStart{
    width: 100%;
    overflow: hidden;
    margin: 0;
}


/* media query */
/* @media screen and (min-width:1740px) {
    h1{
        font-size:4rem;
        font-weight:800;
        letter-spacing:-2%;
        line-height:76px;
    }
    h2{
        font-size:3.5rem;
        font-weight:800;
        letter-spacing:0%;
        line-height:68px;
    }
    h3{
        font-size:3rem;
        font-weight:700;
        letter-spacing:0%;
        line-height:60px;
    }
    h4{
        font-size:2.25rem;
        font-weight:600;
        letter-spacing:0%;
        line-height:52px;
    }
    .body{
        font-size:2rem;
        font-weight:600;
        letter-spacing:0%;
        line-height:44px;
    }
    .lead{
        font-weight: 600;
        font-size: 2.125rem;
        line-height: 46px;
    }
    .caption{
        font-weight: 600;
        font-size: 1.875rem;
        line-height: 42px
    }
    .subheading{
        font-size: 1.875rem;
        font-weight: 800;
        line-height: 42px;
        letter-spacing: 0em;
        color: #006AAA;
    }

} */
@media screen and (max-width:1950px) {
    .btnPrimary{
        padding: 12px auto;
    }
    .sectLeft{
        gap:27%;
    }
}
@media screen and (max-width:1750px) {
    .sectLeft{
        gap:23%;
    }

}
@media screen and (max-width:1500px) {
/*     .sectLeft img{
        margin-left: -18%;
    }
    .sectLeft{
        gap:10%;
    } */


/*     .banner .overlay{
        height: 38vh; 
        height: 100%;
    } */
}
/*@media screen and (max-width:1390px) {
    .banner .overlay{
         height: 30vh; 
        height: 100%;
    }
}*/
@media screen and (max-width:1200px) {
    .btnPrimary{
        padding: 12px auto;
    }
    /* .banner .overlay{
        /* height: 25vh; 
        height: 100%;
    } */
}

/*medium devices*/
@media screen and (max-width:1024px) {
    .btnPrimary{
        height: auto;
        padding: 15px 5%;
    }
    .sectLeft{
        width: 95%;
        margin:120px -6%;
        display: grid;
        grid-template-columns: 30% 46% ;
        gap:24%;
        overflow-x: hidden;
    }
/*     #outBtn{
        margin-left:25%;
    } */
    .sectLeft img{
        width: 170%;
    }
/*     .banner .overlay{
       
        height: 100%;
    } */
    .banner .overlay .sbtxt{
        top:70px;
    }
    
    
}
/*tab devices*/
@media screen and (max-width:768px) {
    .btnPrimary{
        height: auto;
        /* width: 65%; */
        /* padding:15px auto; */
        margin: auto auto;
        
    }
/*     #outBtn{
        width: 58%;
        margin-left:18%;
    } */
/*     .banner .overlay{
       
        height: 100%;
    } */
    .banner .overlay .sbtxt{
        top:70px;
    }
    .sectRight {
        width: 82.8%;
        margin:50px 9%;
        display: block;
        overflow-x:none;
        
    }
/*     .sectRight img{
       width: 100%;
    } */
    .sectLeft{
        width: 82.8%;
        margin:50px 9%;
        display: block;
        overflow-x: none;
    }
}
@media screen and (max-width:500px) {
/*     #outBtn{
        width:80%;
        margin-left:10%;
    } */
    html{
        width: 100.5%;
        overflow-x: hidden;
    }
    body{
        width: 100.5%;
    }
}
@media screen and (max-width:447px) {
/*     #outBtn{
        width:80%;
        margin-left:6%;
    } */
}
/*mobile devices*/
@media screen and (max-width:480px) {
    body{
        overflow-x: hidden;
    }
    h1{
        font-size:2rem;
        line-height: 35px;
    }
    h2{
        font-size:1.75rem;
        line-height: 35px;
    }
    h3{
        font-size:1.5rem;
        line-height: 35px;
    } 
    .sectRight {
        width: 82.8%;
        margin:50px 9%;
        display: block;
        overflow-x:none;
        
    }
    .sectRight img{
       width: 100%;
    }
    .sectLeft{
        width: 82.8%;
        margin:50px 9%;
        display: block;
        overflow-x: none;
    }
    .doublePage{
        display: block;
    }
    .doublePage div{
        width: 100%;
        margin-bottom: 42px;
    }
/*     .banner .overlay{
      
        height: 100%;
    } */
    .banner .overlay .sbtxt{
        left: 0%;
        top:30px;
        margin:0 3.7%;
    }
     
}
