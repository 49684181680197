

.Bathroom .sSect1 button{
    margin-top:80px;
}
@media screen and (max-width:1950px) {
#down{
    margin-top: 45px;
}
#bathBtn{
    margin:45px auto; 
    
} 
}
@media screen and (max-width:768px) {
    #down{
        margin-top: 0;
    }
    #imgDown{
        margin-top:150px ;
    }
    #img2Down{
        margin-top:85px ;
    }
/*     #bathBtn{
        margin-top: 45px;
        margin-left: 120px; 
        
    }  */
}
