
.sSect1 .sGallery{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8%;
    width: 100%;
}
.sSect1 .sGallery img{
    width: inherit;
}
.sSect1 button{
    margin: 0 auto;
}


.skitchen{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6.5%;
    width: 100%;
}
#ksectLeft{
    gap: 22%;
}
.skitchen img{
    width: 100%;
}
.sSect3 button{
    margin: 0 auto;
}
.sSect4 button{
    margin: 0 auto;
}

#outBtn{
    margin:0 auto;
    text-align: center;
}

.sectNews{
    width: 82.8%;
    margin:50px 9%;
    margin-top: 180px;
    border-radius: 37px;
    background: #006AAA;
    padding-inline: 4%;
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    gap:1em;
}
.newsImg{
    margin-top: -80px;
}
.sectNews .newsTxt{
    color: white;
    margin-top: 50px;
}
form.news input{
    height: 48px;
    width: 60%;
    border-radius: 4px;
    border:none;
    background: #F2F2F2;

}
form.news input:active {
    border:none;
}
form.news input::placeholder{
    text-align: center;
}
form.news button{
    height: 48px;
    width: auto;
    border: none    ;
    border-radius: 4px;
    padding: 12px 3%;
    background: #F2F2F2;
    margin-left: 8px;
}
.sectNews #p{
    font-weight: 400;
}
#whsSectTxt{
    gap: 27%;
}
#dbuildRight{
    width: 110%;
}
#designRight{
    margin-top: 0;
}
/* outdoor living*/
#outdoorLeft{
    gap:29.5%;
    margin-top: 0;
}
#outdoorright{
    /* gap:29.5%; */
    margin-top: 0;
    margin-bottom:60px;
}
#outdoorLeftTxt{
    margin-top: 90px;
}
#uniRightImg{
    width:110%;
}
/* media query */
@media screen and (max-width:1950px) {
    #ksectLeft{
        gap: 14%;
    }
    #whsSectTxt{
        gap: 12%;
    }
    #outdoorLeft{
        gap:13%;
        margin-top: 0;
    }
    #kitrighttxt{
        margin-top: 85px;
    }
    #uniTxtRight{
        margin-top: 130px;
 
    }
}
@media screen and (max-width:1750px) {
    #ksectLeft{
        gap: 21%;
    }
    #whsSectTxt{
        gap: 22%;
    }
    #kitrighttxt{
        margin-top: 0;
    }
}
@media screen and (max-width:1650px) {
    #ksectLeft{
        gap: 21%;
    }
    #outdoorLeft{
        gap:21%;
    }
}
@media screen and (max-width:1420px) {
    #ksectLeft{
        gap: 22%;
    }
    #ksectLeft img{
        margin-left: -18%;
    }
    #whsSectTxt{
        gap: 26%;
    }
    #outdoorLeft{
        gap:22%;
    }
    #outdoorLeft img{
        margin-left: -18%;
        
    }
}
/*medium devices*/
@media screen and (max-width:1024px) {
    
    .sSect1 .sGallery{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .skitchen{
        display:none;
    }
    .sectNews{
        margin-top: 150px;
        grid-template-columns: 0.5fr 1fr;
        gap:15%;
    }
    .newsImg{
        margin-top: 20px;
        width: 165%;
        margin-left: -10%;
    }
    .sectNews .newsTxt{
        margin-top: 23px;
    }
    form.news{
        padding: 5% 0;
     }
     
    form.news input{
        height: 48px;
        width: 60%;    
    }
    form.news button{
        height: 48px;
        width: 35%;
        margin-left: 8px;
    }
    #uniTxtRight{
        margin-top: 80px;
 
    }
    #ksectLeft{
        margin-top: 0;
    }
    #ksectLeft .sSectTxt{
        margin-top: 20px;
    }
    
}
/*tab devices*/
@media screen and (max-width:768px) {
    #ksectLeft{
        margin-top: 0;
    }
    #ksectLeft img{
        margin-top: 0;
    }
    .Service .banner img{
        height: 330px;
    }
    .sSect1 .sGallery{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .sSect1 .sGallery img{
        width: 100%;
    }
    .sSect1 button{
        margin: 0 auto;
    }
    .skitchen{
        display:none;
    }
    .sSect3 button{
        margin: 0 auto;
    }
    .sSect4 button{
        margin: 0 auto;
    }
    .sectNews{
        grid-template-columns: 1fr;
        gap:15%;
    }
    .newsImg{
        display: none;
    }
    #outdoorLeft, #outdoorright{
        display: block;
        margin: 80px auto;
    }
    #outdoorLeft img, #outdoorright img{
        width: 100%;
    }
    
    #uniTxtRight{
        margin-top: 80px;
 
    }
}
/*mobile devices*/
@media screen and (max-width:480px) {
    .sSect1 button{
        margin: 0 auto;
    }
    .sSect1 .sGallery{
        display: grid;
        grid-template-columns:1fr 1fr;
    }
    .sectNews{
        grid-template-columns:1fr;
        gap:15%;
        margin-top: 90px;
    }
    .newsImg{
        display: none;
    }
    .sectNews .newsTxt{
        margin-top: 23px;
        padding: 3%;
    }
    form.news{
        width: 100%;  
        padding: 5%;  
    }
    form.news input{
        width: 100%;    
    }
    form.news button{
       
        width: 50%;
        margin-top: 10px;
        margin-left: 56px;
    }
}