
.Testimony .sSect1 .aboutTestimony{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4%;
    row-gap: 18px;

}
.aboutTestimony .testiTxt{
    width: 90%;
    text-align: center;
    margin: 10px 4.7%;

}
.aboutTestimony .testiTxt img{
    margin: 15px 0;

}
.aboutTestimony .testiPack{
    background: #D9D9D9;
    border-radius: 25px;
    text-align: center;
}
.aboutTestimony .testiPack:hover{
    background: #006AAA;
    color: #FFFFFF;
    transition-duration: 0.7s;
}
.testiUser{
  margin-top: -4%;
}
.testiUser img{
    width: 15%;
}

@media screen and (min-width:1750px) {
    #testiStart{
        margin-top: 90px;
    }
}
@media screen and (max-width:900px) {
    .Testimony .sSect1 .aboutTestimony{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4%;
        row-gap: 18px;
    
    }
}
@media screen and (max-width:480px) {
    .Testimony .sSect1 .aboutTestimony{
        display: grid;
        grid-template-columns: 1fr;
        gap: 4%;
        row-gap: 18px;
    
    }
}