.sGallery .rgalImg{
    width: inherit;
}
#roombtn{
    margin: 0 auto;
}
.raSect2 button {
    margin: 0 37%;

}
.rdouble{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6%;
    width: 100%;
}
.rdouble img{
   width: inherit;
}


/* media query */

/*medium devices*/
@media screen and (max-width:1200px) {
    .raSect2 button {
        text-align: center;
        margin: -30px 40%;
        width: 31%;
    }    
    
}
@media screen and (max-width:1024px) {
    
    
}
/*tab devices*/
@media screen and (max-width:768px) {

    .raSect2 button {
        text-align: center;
        margin: -30px 17%;
        width: 54%;
    }    
    
    
}
/*mobile devices*/
@media screen and (max-width:480px) {

}