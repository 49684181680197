#aboutBanner{
    height:auto;
}
#abL{
    margin-top:0px;
    margin-bottom: 5px;
}
#abL p{
   overflow-wrap: break-word;
}

#sR{
    margin-top: 0px;
    margin-bottom: 0px;
}
.aboutRight{
    margin-top: 0px;
}
.aboutLeft img{
    width:180%;
}
.aboutLeft div{
    margin-top: 60px;
}
.aboutLeft div li{
    line-height: 30px;
}
.aboutRight img{
    
    width:180%;
}
#aright2{
    width:130%;
}
.aboutLeft div{
    margin-top: 60px;
}
.ablist{
    list-style: outside;
}
@media screen and (min-width:1740px) {
    #abL{
      gap:20%;
    }
    #sR .sSectTxt .body{
        line-height: 33px;
    }
    #abL .sSectTxt .body{
        line-height: 35px;
    }
/*     #aboutBanner{
        height:480px;
    } */
    .aboutRight img{
    
        width:fit-content;
    }
    #aright1{
        margin-top:-200px;
        width: 140%;
    }
    #aright2{
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .aboutLeft img{
        width:100%;
        margin-left: 52%;
        margin-right:0;
    }
}
@media screen and (max-width:1650px) {

    #aright1{
        margin-top:-200px;
        width: 150%;
    }
    #aright2{
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .aboutLeft img{
        width:100%;
        margin-left: 52%;
        margin-right:0;
    }
}
@media screen and (max-width:1450px) {
    #abL{
      gap:24%;
    }/*
    #sR .sSectTxt .body{
        line-height: 33px;
    }
    #abL .sSectTxt .body{
        line-height: 35px;
    } */
/*     #aboutBanner{
        height:46vh;
    } */
    .aboutRight img{
    
        width:fit-content;
    }
    #aright1{
        margin-top:-200px;
        width: 180%;
    }
    #aright2{
        width: 120%;
        margin-right: 0;
        margin-left: 0;
    }
    .aboutLeft img{
        width:130%;
        margin-left: 52%;
        margin-right:0;
    }
}
@media screen and (max-width:850px){
/*     #abL{
        grid-template-columns: 1fr;
    }
    #sR{
        grid-template-columns: 1fr;
    } */
    #aleft1{
        /* width: 100%; */
        overflow: visible;
        margin: none;
    }

    #aboutBtn{
        margin-top: 40px;
    }
    #aboutNews{
        margin-top: 100px;
    }
}
@media screen and (max-width:780px) {
   #aright1{
        display: none;
    }
    #aright2{
       display: none;
    } 
}
@media screen and (max-width:480px) {
    .aboutLeft img{
        width:50%;
    }
    #aleft1{
        margin-left: 0%;
        width:100%;
    }
    #aright1{
        display:none;
    }
    #aright2{
        margin-top:10%;
        width:100%;
        display:none;
    }
}