
.navbar {
  background-color: var(--bs-body-bg);

}
#navbar{
  text-align: center;
  justify-content: center;
}
.navbar .navbar-brand {
  color: #323232;
  font-weight: bold;
  margin-right: 5%;
}
.logoImg{
  width:110%;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #323232;
}

.navbar .navbar-nav .nav-link {
  color: #323232;
  font-weight: bold;
  margin-right: 10px;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: #323232;
}

.navbar .navbar-toggler {
  border-color: #323232;
}

.navbar .navbar-toggler-icon {
  color: #006AAA;
}

.navbar .nav-link .dropdown-menu {
  background-color: var(--bs-body-bg);
  
}
.dropdown-menu{
    padding: 9px;
    width: 250px;
}
.navbar .nav-link .dropdown-item {
  color: #323232;
  
}
.dropdown-item{
    margin: 22px auto;
    font-size: 16px;
    font-family: Nunito Sans;
    font-weight: 700;
    line-height: 24px;
}
.navbar .nav-link .dropdown-item:hover,
.navbar .nav-link .dropdown-item:focus {
  background-color: #006AAA;
}
.dropdown-item:hover{
  background-color: #006AAA;
  color: #f2f2f2;
}

.navbar .nav-link .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.navbar .nav-link .dropdown-menu::before {
  position: absolute;
  top: -0.5em;
  left: 1em;
  display: inline-block;
  border-right: 0.5em solid transparent;
  border-bottom: 0.5em solid var(--bs-body-bg);
  border-left: 0.5em solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.navbar .nav-link .dropdown-menu::after {
  position: absolute;
  top: -0.4em;
  left: 1.1em;
  display: inline-block;
  border-right: 0.4em solid transparent;
  border-bottom: 0.4em solid var(--bs-body-bg);
  border-left: 0.4em solid transparent;
  content: "";
}

.navbar .nav-link .dropdown-menu-right::before {
  right: 1em;
  left: auto;
}

.navbar .nav-link .dropdown-menu-right::after {
  right: 1.1em;
  left: auto;
}
.navBtn{
    border-radius: 4px;
    background: #006AAA;
    color: #FFF;
}
.navSchedule{
  text-align:center;
}
.navSchedule p{
  margin-bottom: auto;
}
@media (max-width: 767.98px) {
  .navbar .navbar-nav {
    flex-direction: column;
  }

  .navbar .navbar-nav .nav-link {
    margin-bottom: 10px;
  }

  .navbar .navbar-nav .nav-link:last-child {
    margin-bottom: 0;
  }

  .navbar .navbar-collapse {
    margin-top: 10px;
  }
  .navBtn{
    padding: 2%;
    text-align: center;
}
}

@media (min-width: 768px) {
  .navbar .navbar-nav .dropdown-menu {
    margin-top: 0;
  }

  .navbar .navbar-nav .dropdown:hover .dropdown-menu,
  .navbar .navbar-nav .dropdown:focus .dropdown-menu {
    display: block;
  }

  .navbar .navbar-nav .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .navBtn{
    padding: 2%;
    text-align: center;
  }
}
